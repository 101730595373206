import Vue from 'vue'
import VueRouter from 'vue-router'

/* --- 基础页面 --- */
const SignIn = () => import(/* webpackChunkName: 'components_home' */ './components/SignIn')
const Home = () => import(/* webpackChunkName: 'components_home' */ "./components/Home")

// 配置管理
const WebConfigListView = () => import(/* webpackChunkName: 'view_index' */ "./views/webConfig/webConfigListView.vue")
const WebConfigDetailView = () => import(/* webpackChunkName: 'view_index' */ "./views/webConfig/webConfigDetailView.vue")

/* --- 展示位管理 --- */
const BannerClassListView = () => import(/* webpackChunkName: 'view_banners' */ "./views/banner/class/BannerClassListView")
const BannerClassDetailView = () => import(/* webpackChunkName: 'view_banners' */ "./views/banner/class/BannerClassDetailView")
const BannerListView = () => import(/* webpackChunkName: 'view_banners' */ "./views/banner/index/BannerListView")
const BannerDetailView = () => import(/* webpackChunkName: 'view_banners' */ "./views/banner/index/BannerDetailView")
/* --- 产品管理 --- */
const ProductListView = () => import(/* webpackChunkName: 'view_product' */ "./views/product/index/ProductListView")
const ProductDetailView = () => import(/* webpackChunkName: 'view_product' */ "./views/product/index/ProductDetailListView")
const ProductSkuListModule = () => import(/* webpackChunkName: 'view_product' */ "./views/product/index/module/ProductSkuListModule")
const ProductSkuDetailModule = () => import(/* webpackChunkName: 'view_product' */ "./views/product/index/module/ProductDetailSkuModule")
/* --- 产品详情编辑 --- */
const ProductDetailRichTextTemplate = () => import(/* webpackChunkName: 'view_product' */ "./views/product/detail/template/ProductDetailRichTextTemplate")
/* --- 产品管理-(类型) --- */
const ProductTypeListView = () => import(/* webpackChunkName: 'view_product' */ "./views/product/type/ProductTypeListView")
const ProductTypeDetailView = () => import(/* webpackChunkName: 'view_product' */ "./views/product/type/ProductTypeDetailView")

/* --- 产品详情模块 --- */
const ProductDetailCategoryView = () => import(/* webpackChunkName: 'view_product' */ "./views/product/detail/category/ProductDetailCategoryView")
const ProductDetailCategoryDetailView = () => import(/* webpackChunkName: 'view_product' */ "./views/product/detail/category/ProductDetailCategoryDetailView")
const ProductDetailModuleView = () => import(/* webpackChunkName: 'view_product' */ "./views/product/detail/module/ProductDetailModuleView")
const ProductDetailModuleDetailView = () => import(/* webpackChunkName: 'view_product' */ "./views/product/detail/module/ProductDetailModuleDetailView")

/* --- 内容管理 --- */
const ContentListView = () => import(/* webpackChunkName: 'view_content' */ "./views/content/index/ContentListView")
const ContentDetailView = () => import(/* webpackChunkName: 'view_content' */ "./views/content/index/ContentDetailView")
const ContentNewView = () => import(/* webpackChunkName: 'view_content' */ "./views/content/index/ContentNewView")
/* --- 内容管理-(类型) --- */
const ContentTypeListView = () => import(/* webpackChunkName: 'view_content' */ "./views/content/type/ContentTypeListView")
const ContentTypeDetailView = () => import(/* webpackChunkName: 'view_content' */ "./views/content/type/ContentTypeDetailView")
/* --- 内容管理-(分类) --- */
const ContentCategoryListView = () => import(/* webpackChunkName: 'view_content' */ "./views/content/category/ContentCategoryListView")
const ContentCategoryDetailView = () => import(/* webpackChunkName: 'view_content' */ "./views/content/category/ContentCategoryDetailView")

/* 权限管理 */
const RoleDetailView = () => import(/* webpackChunkName: 'view_role' */ "./views/auth/role/RoleDetailView")
const RoleListView = () => import(/* webpackChunkName: 'view_role' */ "./views/auth/role/RoleListView")
const AuthCategoryDetail = () => import(/* webpackChunkName: 'view_role' */ "./views/auth/category/AuthCategoryDetail")
const AuthCategoryList = () => import(/* webpackChunkName: 'view_role' */ "./views/auth/category/AuthCategoryList")
const ApiDetailView = () => import(/* webpackChunkName: 'view_role' */ "./views/auth/api/ApiDetailView")
const ApiListView = () => import(/* webpackChunkName: 'view_role' */ "./views/auth/api/ApiListView")
const AdminListView = () => import(/* webpackChunkName: 'view_role' */ "./views/auth/admin/AdminListView")
const AdminDetailView = () => import(/* webpackChunkName: 'view_role' */ "./views/auth/admin/AdminDetailView")

/* --- 文件管理 --- */
const FileListView = () => import(/* webpackChunkName: 'view_file' */ "./views/file/FileListView")
/* 用户管理 */
// const UpdateThePasswordView = () => import(/* webpackChunkName: 'view_user' */ "./views/user/UpdateThePasswordView")
// const UpdateUserInfoView = () => import(/* webpackChunkName: 'view_user' */  "./views/user/UpdateUserInfoView")
/* 关于系统 */
const AboutSystemView = () => import(/* webpackChunkName: 'view_user' */ "./views/user/AboutSystemView")

// 账号管理
const AccountListView = () => import(/* webpackChunkName: 'view_account' */ "./views/account/AccountListView")
const AccountListDetailView = () => import(/* webpackChunkName: 'view_account' */ "./views/account/AccountListDetailView")
// const ForgotPasswordView = () => import(/* webpackChunkName: 'components_home' */ "./views/user/ForgotPasswordView")
const UpdateThePasswordView = () => import(/* webpackChunkName: 'view_user' */ "./views/user/UpdateThePasswordView")

// 验真码管理
const CodeListView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/index/CodeListView")
const CodeDetailView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/index/CodeDetailView")
const CodeBatchListView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/batch/CodeBatchListView")
const CodeBatchDetailView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/batch/CodeBatchDetailView")
const CodeFindRulesListView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/rules/CodeFindRulesListView")
const CodeFindRulesDetailView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/rules/CodeFindRulesDetailView")
const CodeRecordListView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/record/CodeRecordListView")
const CodeRecordDetailView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/record/CodeRecordDetailView")
const CodeRecordMapView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/record/CodeRecordMapView")
const CodeUploadListView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/upload/CodeUploadListView")
const CodeUploadDetailView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/upload/CodeUploadDetailView")
const CodeUploadErrListView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/uploadErr/CodeUploadErrListView")
const CodeStatisticsListView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/statistics/CodeStatisticsListView")

// const ProductPlanListView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/plan/ProductPlanListView")
// const ProductPlanDetailView = () => import(/* webpackChunkName: 'view_code' */ "./views/code/plan/ProductPlanDetailView")

/* 全球合作伙伴 */
const GlobalPartnersListView = () => import(/* webpackChunkName: 'view_global_partners' */ "./views/brand/partners/index/GlobalPartnersListView")
const GlobalPartnersDetailView = () => import(/* webpackChunkName: 'view_global_partners' */ "./views/brand/partners/index/GlobalPartnersDetailView")
const GlobalPartnersRegionListView = () => import(/* webpackChunkName: 'view_global_partners' */ "./views/brand/partners/region/GlobalPartnersRegionListView")
const GlobalPartnersRegionDetailView = () => import(/* webpackChunkName: 'view_global_partners' */ "./views/brand/partners/region/GlobalPartnersRegionDetailView")
const GlobalPartnersStateListView = () => import(/* webpackChunkName: 'view_global_partners' */ "./views/brand/partners/state/GlobalPartnersStateListView")
const GlobalPartnersStateDetailView = () => import(/* webpackChunkName: 'view_global_partners' */ "./views/brand/partners/state/GlobalPartnersStateDetailView")
const GlobalPartnersProductListView = () => import(/* webpackChunkName: 'view_global_partners' */ "./views/brand/partners/product/GlobalPartnersProductListView")
const GlobalPartnersProductDetailView = () => import(/* webpackChunkName: 'view_global_partners' */ "./views/brand/partners/product/GlobalPartnersProductDetailView")

// seo管理
const SeoListView = () => import(/* webpackChunkName: 'view_SEO' */ "./views/seo/SeoListView")
const SeoDetailView = () => import(/* webpackChunkName: 'view_SEO' */ "./views/seo/SeoDetailView")

// log管理
const SiteOverview = () => import(/* webpackChunkName: 'view_log' */ "./views/log/site/overview/index.vue");
const SiteOverviewSource = () => import(/* webpackChunkName: 'view_log' */ "./views/log/site/source/index.vue");
const SiteOverviewVisit = () => import(/* webpackChunkName: 'view_log' */ "./views/log/site/visit/index.vue");
const SiteOverviewDistrict = () => import(/* webpackChunkName: 'view_log' */ "./views/log/site/district/index.vue");
const SiteOverviewTrend = () => import(/* webpackChunkName: 'view_log' */ "./views/log/site/trend/index.vue");

// 抽奖管理
const LotteryRecordList = () => import(/* webpackChunkName: 'view_lottery' */ "./views/lottery/record/recordListView.vue")
const LotteryPrizeListView = () => import(/* webpackChunkName: 'view_lottery' */ "./views/lottery/prize/prizeListView.vue")
const LotteryPrizeDetailView = () => import(/* webpackChunkName: 'view_lottery' */ "./views/lottery/prize/prizeDetailView.vue")
const LotteryListView = () => import(/* webpackChunkName: 'view_lottery' */ "./views/lottery/index/lotteryListView.vue")
const LotteryDetailView = () => import(/* webpackChunkName: 'view_lottery' */ "./views/lottery/index/lotteryDetailView.vue")

// 号码抽奖
const NumberLotteryActivityView = () => import(/* webpackChunkName: 'view_lottery' */ "./views/numberLottery/numberLotteryActivityView.vue")

// const QuestionnaireListView = () => import(/* webpackChunkName: 'view_questionnaire' */ "./views/questionnaire/index/QuestionnaireListView.vue")
// const QuestionnaireDetailView = () => import(/* webpackChunkName: 'view_questionnaire' */ "./views/questionnaire/index/QuestionnaireDetailView.vue")
// const QuestionnaireAnswerListView = () => import(/* webpackChunkName: 'view_questionnaire' */ "./views/questionnaire/answer/QuestionnaireAnswerListView.vue")
// const QuestionnaireAnswerDetailView = () => import(/* webpackChunkName: 'view_questionnaire' */ "./views/questionnaire/answer/QuestionnaireAnswerDetailView.vue")

// 随机码管理
const RandomCodeListView = () => import(/* webpackChunkName: 'view_code' */ "./views/randomCode/index/RandomCodeListView")
const RandomCodeDetailView = () => import(/* webpackChunkName: 'view_code' */ "./views/randomCode/index/RandomCodeDetailView")
const RandomCodeBatchListView = () => import(/* webpackChunkName: 'view_code' */ "./views/randomCode/batch/RandomCodeBatchListView")
const RandomCodeBatchDetailView = () => import(/* webpackChunkName: 'view_code' */ "./views/randomCode/batch/RandomCodeBatchDetailView")

// 兑奖码管理
const RedeemCodeListView = () => import(/* webpackChunkName: 'view_code' */ "./views/redeemCode/index/RedeemCodeListView")
const RedeemCodeDetailView = () => import(/* webpackChunkName: 'view_code' */ "./views/redeemCode/index/RedeemCodeDetailView")
const RedeemCodeBatchListView = () => import(/* webpackChunkName: 'view_code' */ "./views/redeemCode/batch/RedeemCodeBatchListView")
const RedeemCodeBatchDetailView = () => import(/* webpackChunkName: 'view_code' */ "./views/redeemCode/batch/RedeemCodeBatchDetailView")
const RedeemCodeUpdateRecordListView = () => import(/* webpackChunkName: 'view_code' */ "./views/redeemCode/update/RedeemCodeUpdateRecordListView")
const RedeemCodeUpdateRecordDetailView = () => import(/* webpackChunkName: 'view_code' */ "./views/redeemCode/update/RedeemCodeUpdateRecordDetailView")
Vue.use(VueRouter)

const routes = [
    {
        path: '/sign-in',
        component: SignIn,
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'user-update-password',
                component: UpdateThePasswordView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'account-list',
                component: AccountListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'account-detail-list',
                component: AccountListDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'about-system',
                component: AboutSystemView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: '/webConfig',
                component: WebConfigListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: '/webConfig-detail',
                component: WebConfigDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'product',
                component: ProductListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'product-detail',
                component: ProductDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'product-detail-template-rich-text',
                component: ProductDetailRichTextTemplate,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'product-detail',
                component: ProductSkuDetailModule,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'product-detail',
                component: ProductSkuListModule,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'product-type',
                component: ProductTypeListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'product-type-detail',
                component: ProductTypeDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'product-detail-category',
                component: ProductDetailCategoryView,
                meta: { requiresAuth: true, requiresAdmin: false, keepAlive: true }
            },
            {
                path: 'product-detail-category-info',
                component: ProductDetailCategoryDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'product-detail-module',
                component: ProductDetailModuleView,
                meta: { requiresAuth: true, requiresAdmin: false, keepAlive: true }
            },
            {
                path: 'product-detail-module-detail',
                component: ProductDetailModuleDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'content',
                component: ContentListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'content-detail',
                component: ContentDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'content-new',
                component: ContentNewView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'content-type',
                component: ContentTypeListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'content-type-detail',
                component: ContentTypeDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'content-category',
                component: ContentCategoryListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'content-category-detail',
                component: ContentCategoryDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'banner-list',
                component: BannerListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'banner-detail',
                component: BannerDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'banner-class-list',
                component: BannerClassListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'banner-class-detail',
                component: BannerClassDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'file',
                component: FileListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'admin',
                component: AdminListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'admin-detail',
                component: AdminDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'admin-auth-api',
                component: ApiListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'admin-auth-api-detail',
                component: ApiDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'admin-auth-category',
                component: AuthCategoryList,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'admin-auth-category-detail',
                component: AuthCategoryDetail,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'admin-auth-role',
                component: RoleListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'admin-auth-role-detail',
                component: RoleDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'code-list',
                component: CodeListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'code-detail',
                component: CodeDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'code-batch-list',
                component: CodeBatchListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'code-batch-detail',
                component: CodeBatchDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'code-rules-list',
                component: CodeFindRulesListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'code-rules-detail',
                component: CodeFindRulesDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'code-record-list',
                component: CodeRecordListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'code-record-detail',
                component: CodeRecordDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'code-record-map',
                component: CodeRecordMapView,
                meta: { requiresAuth: false, requiresAdmin: false }
            },
            {
                path: 'code-upload',
                component: CodeUploadListView,
                meta: { requiresAuth: false, requiresAdmin: false }
            },
            {
                path: 'code-upload-detail',
                component: CodeUploadDetailView,
                meta: { requiresAuth: false, requiresAdmin: false }
            },
            {
                path: 'code-upload-err',
                component: CodeUploadErrListView,
                meta: {requiresAuth: false, requiresAdmin: false}
            },
            {
                path: 'code-statistics',
                component: CodeStatisticsListView,
                meta: { requiresAuth: false, requiresAdmin: false }
            },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'global-partners',
                component: GlobalPartnersListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'global-partners-detail',
                component: GlobalPartnersDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'global-partners-region',
                component: GlobalPartnersRegionListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'global-partners-region-detail',
                component: GlobalPartnersRegionDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'global-partners-state',
                component: GlobalPartnersStateListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'global-partners-state-detail',
                component: GlobalPartnersStateDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'global-partners-product',
                component: GlobalPartnersProductListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'global-partners-product-detail',
                component: GlobalPartnersProductDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'seo-list',
                component: SeoListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'seo-detail',
                component: SeoDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: "/site-overview",
                component: SiteOverview,
                meta: { requiresAuth: true, requiresAdmin: false },
            },
            {
                path: "/site-overview-source",
                component: SiteOverviewSource,
                meta: { requiresAuth: true, requiresAdmin: false },
            },
            {
                path: "/site-overview-visit",
                component: SiteOverviewVisit,
                meta: { requiresAuth: true, requiresAdmin: false },
            },
            {
                path: "/site-overview-district",
                component: SiteOverviewDistrict,
                meta: { requiresAuth: true, requiresAdmin: false },
            },
            {
                path: "/site-overview-trend",
                component: SiteOverviewTrend,
                meta: { requiresAuth: true, requiresAdmin: false },
            },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'lottery-records',
                component: LotteryRecordList,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'lottery-prize',
                component: LotteryPrizeListView,
                meta: { requiresAuth: true, requiresAdmin: false, keepAlive: true }
            },
            {
                path: 'lottery-prize-detail',
                component: LotteryPrizeDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'lottery-list',
                component: LotteryListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'lottery-detail',
                component: LotteryDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            // {
            //     path: 'questionnaire',
            //     component: QuestionnaireListView,
            //     meta: { requiresAuth: true, requiresAdmin: false }
            // },
            // {
            //     path: 'questionnaire-detail',
            //     component: QuestionnaireDetailView,
            //     meta: { requiresAuth: true, requiresAdmin: false }
            // },
            // {
            //     path: 'questionnaire-answer-list',
            //     component: QuestionnaireAnswerListView,
            //     meta: { requiresAuth: true, requiresAdmin: false }
            // },
            // {
            //     path: 'questionnaire-answer-detail',
            //     component: QuestionnaireAnswerDetailView,
            //     meta: { requiresAuth: true, requiresAdmin: false }
            // },
            {
                path: 'number-lottery',
                component: NumberLotteryActivityView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            // {
            //     path: 'number-lottery-list',
            //     component: NumberLotteryListView,
            //     meta: { requiresAuth: true, requiresAdmin: false }
            // },
            // {
            //     path: 'number-lottery-detail',
            //     component: NumberLotteryDetailView,
            //     meta: { requiresAuth: true, requiresAdmin: false }
            // },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'random-code-list',
                component: RandomCodeListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'random-code-detail',
                component: RandomCodeDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'random-code-batch-list',
                component: RandomCodeBatchListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'random-code-batch-detail',
                component: RandomCodeBatchDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
        ]
    },
    {
        path: '/',
        component: Home,
        children: [
            {
                path: 'redeem-code-list',
                component: RedeemCodeListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'redeem-code-detail',
                component: RedeemCodeDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'redeem-code-batch-list',
                component: RedeemCodeBatchListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'redeem-code-batch-detail',
                component: RedeemCodeBatchDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'redeem-code-update-record-list',
                component: RedeemCodeUpdateRecordListView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
            {
                path: 'redeem-code-update-record-detail',
                component: RedeemCodeUpdateRecordDetailView,
                meta: { requiresAuth: true, requiresAdmin: false }
            },
        ]
    },

];

const router = new VueRouter({
    mode: 'history',
    routes: routes  // (缩写) 相当于 routes: routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //如果未登录，跳转到登录页
        if (!Vue.prototype.$common.isAuthenticated()) {
            next({ path: '/sign-in' })
        } else {
            //获取用户信息
            let user = Vue.prototype.$common.getGlobalStorage('_user');
            if (user) {
                if (to.meta.requiresAdmin) {
                    next({ path: '/home' })
                } else {
                    next()
                }
            } else {
                next({ path: '/sign-in' })
            }
        }
    } else {
        next() // 确保一定要调用 next()
    }
});

export default router
