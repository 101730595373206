import Vue from 'vue'
import App from './App.vue'
import router from './router.js'

// 引入UI框架
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './theme/index.css';

// 全局资源文件
import global from './components/Common.vue'
// MD5
import md5 from 'js-md5';

// 引入api文件
import api from './api/api.js'

// 全局使用
Vue.prototype.$common = global;
Vue.prototype.$md5 = md5;
Vue.prototype.api = api;

Vue.use(ElementUI);

Vue.config.productionTip = false;

// 时间转换函数
Vue.filter('dateFormat', function (value) {
  if (value == 0 || !value) {
    return '';
  } else {
    if (!isNaN(value)) {
      value = value * 1000;
    }
    let a = new Date(value);
    let year = a.getFullYear();
    let month = a.getMonth() + 1;
    let day = a.getDate();
    if (month.toString().length == 1) {
      month = '0' + month;
    }
    if (day.toString().length == 1) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
  }
});

// 时间转换函数
Vue.filter('dateTimeFormat', function (value) {
  if (value == 0 || !value) {
    return '';
  } else {
    if (!isNaN(value)) {
      value = value * 1000;
    }
    let a = new Date(value), year = a.getFullYear(), month = a.getMonth() + 1, day = a.getDate();
    let h = a.getHours(), m = a.getMinutes(), s = a.getSeconds();
    if (month.toString().length == 1) {
      month = '0' + month;
    }
    if (day.toString().length == 1) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day + ' ' + h + ':' + m + ':' + s;
  }
});
// 防抖
Vue.directive('debounceClick', {
  inserted(el, binding) {
      el.addEventListener('click', e => {
          el.classList.add('is-disabled')
          el.disabled = true
          setTimeout(() => {
              el.disabled = false
              el.classList.remove('is-disabled')
          }, 1000)
      })
  }
})
//根据屏幕大小缩放
if (window.screen.width < '800')
  document.write('<body style="zoom :55%">');
else if (window.screen.width < '1400')
  document.write('<body style="zoom :75%">');

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
